@import "_partial/reset"
@import "_partial/satoshi"

@keyframes gradient
  0%
    background-position: 0% 50%
  50%
    background-position: 100% 50%
	100%
    background-position: 0% 50%

body
  background-color: #fff
  color: #767676
  font-family: Satoshi-Medium, sans-serif
  font-size: 24px
  line-height: 28px
  padding: 20rem 40px 3rem

h1
  font-family: Satoshi-Bold, sans-serif
  color: #020203
  font-size: 9vw
  line-height: 1
  font-style: normal
  font-weight: normal
  margin-left: -4px

h2
  font-family: Satoshi-Bold, sans-serif
  color: #020203
  font-size: 5vw
  line-height: 1.3
  font-style: normal
  font-weight: normal
  letter-spacing: -0.04em
  max-width: 30ch
  margin-left: -4px

a
  color: #5f5f5f
  transition: 0.2s
  text-decoration: none
  &:hover
    color: #D69ADE

.global-header
  background: rgba(255, 255, 255, 0.8)
  position: fixed
  display: grid
  grid-template-columns: 1fr 1fr
  padding: 40px
  column-gap: 40px
  top: 0
  left: 0
  width: 100%

  .blog-title
    opacity: 0.5

  .menu
    list-style: none;
    a
      padding-bottom: 0.15em
      border-bottom: 1px solid #fff
    &-item
      display: inline-block
      padding: 0 15px

.global-intro,
.post-intro
  margin-bottom: 40px
  p
    font-style: italic
  .heading
    color: #D69ADE
    position: relative
    z-index: 0

.global-intro
  height: 100vh
  .heading
    background: linear-gradient(-45deg, #EBD9DD, #D8AED3, #9182C4, #7A73D1)
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    background-size: 500% auto;
    animation: gradient 15s ease-in-out infinite alternate;

.post-intro
  h1
    margin: 0 auto 40px
    text-align: center
    width: auto

.post-meta
  text-align: center
  margin: 0 auto 40px auto
  opacity: 0.25

.post-content
  h2
    margin: 0 auto 40px
  h3
    margin: 0 auto 20px

  em
    opacity: 0.8
    text-align: center

  p
    @media screen and (min-width: 728px)
      max-width: 40vw
    margin: 0 auto 40px
    max-width: 65vw
    font-size: 3vmin;
    line-height: 1.3

  img
    @media screen and (min-width: 728px)
      width: 60% 
    border-radius: 20px
    max-width: 1800px
    width: 80%
    margin: 40px auto
  code,
  .code,
  pre
    font-family: Consolas, Monaco, "Lucida Console", Liberation Mono, "Courier New", monospace;
    background: #EBD9DD
    color: #17153B
    font-size: 16px
    padding: 0
    padding: 10px
    &:before
      display: block
      width: calc(100%)
      margin-left: -3px
      margin-top: -3px
      padding: 3px
      text-transform: uppercase
      content: attr(data-lang)
      background: #2E236C
    .o
      color: orange
    .w
      color: white

  
.global-post-list
  padding: 0 0 40px 0
  h2
    margin-bottom: 40px
    &:before
      content: '\2193'
      padding-right: 20px
  .post-title
    &-link
      display: grid
      grid-template-columns: 1fr 1fr 1fr
      justify-content: space-between;
      padding: 10px
      &:hover
        .post-tags,
        .post-date
          opacity: 0.25
  .post-tags,
  .post-date
    opacity: 0
    transition: 0.2s

.global-footer
  text-align: right

.global-return
  @media screen and (min-width: 728px)
    transform: none
    bottom: 40px
    left: 40px
  display: block
  position: fixed
  bottom: 40px
  left: -20px
  transform: rotate(90deg)
  button
    appearance: none
    border: 4px solid transparent
    border-image: linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)
    border-image-slice: 1
    padding: 10px
    background: none
    font-size: 0.75em
    transition: 0.25s
    &:hover 
      opacity: 0.40
      
